import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    countries: [],
    country: {},
})

export const getters = {
    getCountries(state) {
        return state.countries
    },
    getCountry(state) {
        return state.country
    },
}

export const mutations = {
    SET_COUNTRIES(state, payload) {
        state.countries = payload;
    },
    SET_COUNTRY(state, payload) {
        state.country = payload;
    },
}

export const actions = {
    async fetchCountry({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`countries/${payload}`)
            commit("SET_COUNTRY", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchCountries({ commit, state }) {
        try {
            const { data } = await ApiService.get("countries")
            commit("SET_COUNTRIES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createCountry({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("countries", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleDelete({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`countries/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleUpdate({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`countries/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}