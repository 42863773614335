import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  dates: [],
  date: {},
});

export const getters = {
  getDates(state) {
    return state.dates;
  },
  getDate(state) {
    return state.date;
  },
};

export const mutations = {
  SET_DATES(state, payload) {
    state.dates = payload;
  },
  SET_DATE(state, payload) {
    state.date = payload;
  },
};

export const actions = {
  async fetchDate({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `appointments/week_time/${payload}`
      );
      commit("SET_DATE", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchDates({ commit, state }) {
    try {
      const { data } = await ApiService.get("appointments/week_time");
      commit("SET_DATES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async createDate({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("appointments/week_time", payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateDate({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `appointments/week_time/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteDate({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(
        `appointments/week_time/${payload}`
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
