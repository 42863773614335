import { App } from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  static vueInstance;

  /**
   * @description initialize vue axios
   */
  static init(app) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.axios = axios;
    // ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_BASE_API;
    ApiService.setHeader();

    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          store.dispatch("Auth/systemLogout");
          JwtService.destroyToken();
        }
        return error;
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  static setHeader() {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource
   * @param params
   * @returns Promise<AxiosResponse>
   */
  static query(resource, params) {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource
   * @returns Promise<AxiosResponse>
   */
  static get(resource) {
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource
   * @param params
   * @returns Promise<AxiosResponse>
   */
  static post(resource, params) {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource
   * @param params
   * @returns Promise<AxiosResponse>
   */
  static update(resource, params) {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns Promise<AxiosResponse>
   */
  static put(resource, params) {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns Promise<AxiosResponse>
   */
  static patch(resource, params) {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource
   * @returns Promise<AxiosResponse>
   */
  static delete(resource) {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
