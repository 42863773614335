// import MessageBox from './src/main.js';
// export default MessageBox;


import MessageBox from './src/main.js';


// MessageBox.install = function (Vue) {
//     Vue.component(MessageBox.name, MessageBox);
// };

// export const HxMessageBox = MessageBox;

export default MessageBox;