import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    beds: [],
    bed: {},
})

export const getters = {
    getBeds(state) {
        return state.beds
    },
    getBed(state) {
        return state.bed
    },
}

export const mutations = {
    SET_BEDS(state, payload) {
        state.beds = payload;
    },
    SET_BED(state, payload) {
        state.bed = payload;
    },
}

export const actions = {
    async fetchBed({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`beds/${payload}`)
            commit("SET_BED", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchBeds({ commit, state }) {
        try {
            const { data } = await ApiService.get("beds")
            commit("SET_BEDS", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createBed({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("beds", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteBed({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`beds/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateBed({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`beds/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}