export default {
  path: "/configuration",
  redirect: "configuration",
  name: "configuration label",
  meta: {
    auth: true,
  },
  component: () =>
    import(
      /* webpackChunkName: "configuration" */ "@/layouts/configuration.vue"
    ),
  children: [
    {
      path: "time/:id",
      name: "configuration time",
      component: () =>
        import(
          /* webpackChunkName: "configuration-time" */ "@/views/configuration/time.vue"
        ),
    },

    {
      path: "currency/:id",
      name: "configuration currency",
      component: () =>
        import(
          /* webpackChunkName: "configuration-currency" */ "@/views/configuration/currency.vue"
        ),
    },
    {
      path: "owner/:id",
      name: "configuration owner",
      component: () =>
        import(
          /* webpackChunkName: "configuration-owner" */ "@/views/configuration/owner.vue"
        ),
    },
    {
      path: "object/:id",
      name: "configuration object",
      component: () =>
        import(
          /* webpackChunkName: "configuration-object" */ "@/views/configuration/object.vue"
        ),
    },
    {
      path: "bar/:id",
      name: "configuration bar",
      component: () =>
        import(
          /* webpackChunkName: "configuration-bar" */ "@/views/configuration/bar.vue"
        ),
    },

    {
      path: "room/:id",
      name: "configuration room",
      component: () =>
        import(
          /* webpackChunkName: "configuration-room" */ "@/views/configuration/room.vue"
        ),
    },
    {
      path: "facilities/:id",
      name: "configuration facilities",
      component: () =>
        import(
          /* webpackChunkName: "configuration-facilities" */ "@/views/configuration/facilities.vue"
        ),
    },
    {
      path: "integrations/:id",
      name: "configuration integrations",
      component: () =>
        import(
          /* webpackChunkName: "configuration-integrations" */ "@/views/configuration/integrations.vue"
        ),
    },
  ],
};
