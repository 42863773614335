import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  times: [],
  time: {},
});

export const getters = {
  getTimes(state) {
    return state.times;
  },
  getTime(state) {
    return state.time;
  },
};

export const mutations = {
  SET_TIMES(state, payload) {
    state.times = payload;
  },
  SET_TIME(state, payload) {
    state.time = payload;
  },
};

export const actions = {
  async fetchTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `appointments/available_time/${payload}`
      );
      commit("SET_TIME", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchTimes({ commit, state }) {
    try {
      const { data } = await ApiService.get("appointments/available_time");
      commit("SET_TIMES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async createTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        "appointments/available_time",
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `appointments/available_time/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(
        `appointments/available_time/${payload}`
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
