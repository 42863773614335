import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    currencies: [],
    currency: {},
})

export const getters = {
    getCurrencies(state) {
        return state.currencies
    },
    getCurrency(state) {
        return state.currency
    },
}

export const mutations = {
    SET_CURRENCIES(state, payload) {
        state.currencies = payload;
    },
    SET_CURRENCY(state, payload) {
        state.currency = payload;
    },
}

export const actions = {
    async fetchCurrency({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`currencies/${payload}`)
            commit("SET_CURRENCY", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchCurrencies({ commit, state }) {
        try {
            const { data } = await ApiService.get("currencies")
            commit("SET_CURRENCIES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createCurrency({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("currencies", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteCurrency({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`currencies/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateCurrency({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`currencies/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}