export default {
  path: "/",
  redirect: "packages",
  component: () =>
    import(/* webpackChunkName: "landing" */ "@/layouts/pricing.vue"),
  children: [
    {
      path: "privacy",
      name: "privacy-index",
      component: () =>
        import(
          /* webpackChunkName: "privacy-index" */ "@/views/common/PrivacyPolicy.vue"
        ),
    },
    {
      path: "terms",
      name: "terms-index",
      component: () =>
        import(
          /* webpackChunkName: "terms-index" */ "@/views/common/TermsAndConditions.vue"
        ),
    },
    {
      path: "packages",
      name: "packages",
      component: () =>
        import(
          /* webpackChunkName: "packages" */ "@/views/landing/pricing/packages.vue"
        ),
    },

    {
      path: "pricing/:id",
      name: "pricing",
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "pricing" */ "@/views/landing/pricing/pricing.vue"
        ),
    },
    {
      path: "pricing/:id/request",
      name: "pricing-request",
      component: () =>
        import(
          /* webpackChunkName: "request" */ "@/views/landing/pricing/request.vue"
        ),
    },

    {
      path: "apartment",
      name: "apartment",

      component: () =>
        import(
          /* webpackChunkName: "apartment" */ "@/views/landing/apartment.vue"
        ),
    },

    {
      path: "hotel",
      name: "hotel",
      component: () =>
        import(/* webpackChunkName: "hotel" */ "@/views/landing/hotel.vue"),
    },
    {
      path: "call",
      name: "call",
      component: () =>
        import(/* webpackChunkName: "call" */ "@/views/landing/call.vue"),
    },
    {
      path: "hostel",
      name: "hostel",
      component: () =>
        import(/* webpackChunkName: "hostel" */ "@/views/landing/hostel.vue"),
    },
    {
      path: "about-us",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "@/views/landing/about.vue"),
    },

    {
      path: "managment/dashboard",
      name: "managment-dashboard",
      meta: {
        auth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "managment-dashboard" */ "@/views/landing/pricing/packages.vue"
        ),
    },
  ],
};
