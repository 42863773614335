import Vue from "vue";
import Vuex from "vuex";

import * as PricingModule from "@/store/modules/Pricing";
import * as AuthModule from "@/store/modules/Auth";
import * as LabelConfigurationModule from "@/store/modules/LabelConfiguration";
import * as Country from "@/store/modules/admin/Country";
import * as City from "@/store/modules/admin/City";
import * as About from "@/store/modules/admin/About";
import * as Facilities from "@/store/modules/admin/Facilities";
import * as Landing from "@/store/modules/admin/Landing";
import * as User from "@/store/modules/admin/User";
import * as StaticPages from "@/store/modules/admin/StaticPages";
import * as Timezones from "@/store/modules/admin/Timezones";
import * as Currency from "@/store/modules/admin/Currency";
import * as Bed from "@/store/modules/admin/Bed";
import * as Reservation from "@/store/modules/Reservation";
import * as AppointmentRequest from "@/store/modules/admin/AppointmentRequest";
import * as AppointmentDate from "@/store/modules/admin/AppointmentDate";
import * as AppointmentTime from "@/store/modules/admin/AppointmentTime";
import * as Package from "@/store/modules/admin/Package";
import * as HardwareRequest from "@/store/modules/admin/HardwareRequest";
import * as HardwareRequestFile from "@/store/modules/admin/HardwareRequestFile";
import * as Assignment from "@/store/modules/setting/Assignment";
import * as SettingBar from "@/store/modules/setting/Bar";
import * as SettingPersonal from "@/store/modules/setting/Personal";
import * as ManagementcheckIn from "@/store/modules/management/checkIn";
import * as ManagementGuest from "@/store/modules/management/Guest";
import * as ManagementBooking from "@/store/modules/management/BookingN";
import * as ManagementInvoice from "@/store/modules/management/Invoices";
import * as ManagementPartner from "@/store/modules/management/Partners";
import * as UserDashboard from "@/store/modules/Dashboard";
import * as HardwareAddController from "@/store/modules/hardware/AddController";
import * as General from "@/store/modules/General";
import * as Payment from "@/store/modules/admin/Payment";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Pricing: PricingModule,
    Auth: AuthModule,
    LabelConfig: LabelConfigurationModule,
    Country: Country,
    About: About,
    Facilities: Facilities,
    Landing: Landing,
    City: City,
    User: User,
    StaticPages: StaticPages,
    Timezones: Timezones,
    Currency: Currency,
    Bed: Bed,
    Reservation: Reservation,
    AppointmentRequest: AppointmentRequest,
    AppointmentDate: AppointmentDate,
    AppointmentTime: AppointmentTime,
    Package: Package,
    HardwareRequest: HardwareRequest,
    HardwareRequestFile: HardwareRequestFile,
    Assignment: Assignment,
    SettingBar: SettingBar,
    SettingPersonal: SettingPersonal,
    ManagementcheckIn: ManagementcheckIn,
    ManagementGuest: ManagementGuest,
    ManagementBooking: ManagementBooking,
    ManagementInvoice: ManagementInvoice,
    ManagementPartner: ManagementPartner,
    UserDashboard: UserDashboard,
    HardwareAddController: HardwareAddController,
    General: General,
    Payment: Payment
  },
});
export default store;
