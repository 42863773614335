import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    timezones: [],
    timezone: {},
})

export const getters = {
    getTimezones(state) {
        return state.timezones
    },
    getTimezone(state) {
        return state.timezone
    },
}

export const mutations = {
    SET_TIMEZONES(state, payload) {
        state.timezones = payload;
    },
    SET_TIMEZONE(state, payload) {
        state.timezone = payload;
    },
}

export const actions = {
    async fetchTimezone({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`timezones/${payload}`)
            commit("SET_TIMEZONE", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchTimezones({ commit, state }) {
        try {
            const { data } = await ApiService.get("timezones")
            commit("SET_TIMEZONES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createTimezone({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("timezones", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteTimezone({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`timezones/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateTimezone({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`timezones/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}