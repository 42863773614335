import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  packages: [],
  package: {},
});

export const getters = {
  getPackages(state) {
    return state.packages;
  },
  getPackage(state) {
    return state.package;
  },
};

export const mutations = {
  SET_PACKAGES(state, payload) {
    state.packages = payload;
  },
  SET_PACKAGE(state, payload) {
    state.package = payload;
  },
};

export const actions = {
  async fetchPackage({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`package/${payload}`);
      commit("SET_PACKAGE", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchPackages({ commit, state }) {
    try {
      const { data } = await ApiService.get("package");
      commit("SET_PACKAGES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async createPackage({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("package", payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deletePackage({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`package/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async updatePackage({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `package/${payload.get("id")}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
