import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    persons: [],
    person: {},
    general :{}
})

export const getters = {
    getPersons(state) {
        return state.persons
    },
    getGeneralInfo(state) {
        return state.general
    },
    getPerson(state) {
        return state.person
    },
}

export const mutations = {
    SET_PERSONS(state, payload) {
        state.persons = payload;
    },
    SET_GENERAL_INFO(state, payload) {
        state.general = payload;
    },
    SET_PERSON(state, payload) {
        state.person = payload;
    },
}

export const actions = {
    async fetchPerson({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`aboutUs/person/${payload}`)
            commit("SET_PERSON", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchPersons({ commit, state }) {
        try {
            const { data } = await ApiService.get("aboutUs/person")
            commit("SET_PERSONS", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchGeneralInfo({ commit, state }) {
        try {
            const { data } = await ApiService.get("aboutUs/General/single")
            commit("SET_GENERAL_INFO", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createPerson({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("aboutUs/person", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleDelete({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`aboutUs/person/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleUpdate({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`aboutUs/person/${payload.get('id')}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateGeneralInfo({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`aboutUs/General/update`, payload)
            return data
        } catch (error) {
            return error;
        }
    },


}