export default {
  path: "/management",
  component: () =>
    import(
      /* webpackChunkName: "management-layout" */ "@/layouts/management.vue"
    ),
  children: [
    {
      path: "checkIn/:id",
      name: "label management checkIn",
      component: () =>
        import(
          /* webpackChunkName: "label-management" */ "@/views/management/CheckIn.vue"
        ),
    },
    {
      path: "guests/:id",
      name: "label management guests",
      component: () =>
        import(
          /* webpackChunkName: "label-Guests" */ "@/views/management/Guests.vue"
        ),
    },
    {
      path: "booking/:id",
      name: "label management booking",
      component: () =>
        import(
          /* webpackChunkName: "label-BookingN" */ "@/views/management/BookingN.vue"
        ),
    },
    {
      path: "invoices/:id",
      name: "label management invoices",
      component: () =>
        import(
          /* webpackChunkName: "label-Invoices" */ "@/views/management/Invoices.vue"
        ),
    },

    {
      path: "partners/:id",
      name: "label management partners",
      component: () =>
        import(
          /* webpackChunkName: "label-Partners" */ "@/views/management/Partners.vue"
        ),
    },
  ],
};
