export default {
    path: "/hardware",
    component: () =>
        import(
        /* webpackChunkName: "hardware-layout" */ "@/layouts/hardware.vue"
        ),
    children: [
        {
            path: "controller/:id",
            name: "label hardware controller",
            component: () =>
                import(
            /* webpackChunkName: "hardware-controller" */ "@/views/hardware/AddController.vue"
                ),
        },

        {
            path: "management/:id",
            name: "label hardware management",
            component: () =>
                import(
            /* webpackChunkName: "hardware-Management" */ "@/views/hardware/LockManagement.vue"
                ),
        },

        {
            path: "file/:id",
            name: "label hardware file",
            component: () =>
                import(
            /* webpackChunkName: "hardware-file" */ "@/views/hardware/FileInfo.vue"
                ),
        },
        {
            path: "video/:id",
            name: "label hardware video",
            component: () =>
                import(
            /* webpackChunkName: "hardware-video" */ "@/views/hardware/Video.vue"
                ),
        },

    ],
};
