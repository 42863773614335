<template>
  <transition
    name="dialog-fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <template v-if="destroyOnClose">
      <div
        v-if="visible"
        class="hx-dialog__wrapper"
        @click.self="handleWrapperClick"
      >
        <div
          role="dialog"
          :key="key"
          aria-modal="true"
          :aria-label="title || 'dialog'"
          :class="[
            'hx-dialog',
            {
              'is-fullscreen': fullscreen,
              'is-square': square,
              'hx-dialog--center': center,
            },
            customClass,
          ]"
          ref="dialog"
          :style="style"
        >
          <div class="hx-dialog__header">
            <slot name="header" :close="handleClose" :show="showClose">
              <div class="flex justify-between items-center">
                <span class="hx-dialog__title">{{ title }}</span>
                <button
                  type="button"
                  icon
                  class="hx-dialog__headerbtn"
                  aria-label="Close"
                  v-if="showClose"
                  @click="handleClose"
                >
                  <inline-svg
                    class="hx-dialog__close hx-icon hx-icon-close"
                    src="/media/icons/close.svg"
                  ></inline-svg>
                </button>
              </div>
            </slot>
          </div>
          <div
            class="hx-dialog__content flex flex-grow flex-col overflow-y-auto"
          >
            <div class="hx-dialog__body">
              <slot></slot>
            </div>
          </div>

          <div class="hx-dialog__footer" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-show="visible"
        class="hx-dialog__wrapper"
        @click.self="handleWrapperClick"
      >
        <div
          role="dialog"
          :key="key"
          aria-modal="true"
          :aria-label="title || 'dialog'"
          :class="[
            'hx-dialog',
            {
              'is-fullscreen': fullscreen,
              'is-square': square,
              'hx-dialog--center': center,
            },
            customClass,
          ]"
          ref="dialog"
          :style="style"
        >
          <div class="hx-dialog__header">
            <slot name="header" :close="handleClose" :show="showClose">
              <div class="flex justify-between items-center">
                <span class="hx-dialog__title">{{ title }}</span>
                <button
                  type="button"
                  icon
                  class="hx-dialog__headerbtn"
                  aria-label="Close"
                  v-if="showClose"
                  @click="handleClose"
                >
                  <inline-svg
                    class="hx-dialog__close hx-icon hx-icon-close"
                    src="/media/icons/close.svg"
                  ></inline-svg>
                </button>
              </div>
            </slot>
          </div>
          <div
            class="hx-dialog__content flex flex-grow flex-col overflow-y-auto"
          >
            <div class="hx-dialog__body">
              <slot></slot>
            </div>
          </div>

          <div class="hx-dialog__footer" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </template>
  </transition>
</template>

<script>
// import Popup from "@/core/utils/popup";
import Migrating from "@/core/mixins/migrating";
import emitter from "@/core/mixins/emitter";
import Clickoutside from "@/core/utils/clickoutside";
export default {
  name: "HxDialog",
  directives: { Clickoutside },
  mixins: [emitter, Migrating],

  props: {
    title: {
      type: String,
      default: "",
    },
    test: {
      type: Boolean,
      default: false,
    },

    modal: {
      type: Boolean,
      default: false,
    },

    zIndex: {
      type: String,
      default: "20",
    },

    modalAppendToBody: {
      type: Boolean,
      default: true,
    },

    appendToBody: {
      type: Boolean,
      default: true,
    },

    lockScroll: {
      type: Boolean,
      default: true,
    },

    closeOnClickModal: {
      type: Boolean,
      default: false,
    },

    closeOnPressEscape: {
      type: Boolean,
      default: false,
    },

    showClose: {
      type: Boolean,
      default: false,
    },

    width: String,

    fullscreen: Boolean,
    square: Boolean,

    customClass: {
      type: String,
      default: "",
    },

    top: {
      type: String,
      default: "15vh",
    },
    beforeClose: Function,
    center: {
      type: Boolean,
      default: false,
    },

    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    visible: Boolean,
  },

  data() {
    return {
      closed: false,
      key: 0,
      // visible: false,
      rendered: false,
    };
  },

  watch: {
    visible(val) {
      if (val) {
        this.closed = false;
        this.$emit("open");

        this.$nextTick(() => {
          // this.$refs.dialog.scrollTop = 0;
        });
        // this.open();
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
          // document.getElementById("v-modal").appendChild(this.$el);
        }
      } else {
        if (!this.closed) this.$emit("close");
        if (this.destroyOnClose) {
          this.$nextTick(() => {
            this.key++;
          });
        }
      }
    },
  },

  computed: {
    style() {
      let style = {};
      if (!this.fullscreen) {
        // style.marginTop = this.top;
        if (this.width) {
          // style.width = this.width;
        }
      }
      return style;
    },
  },

  methods: {
    getMigratingConfig() {
      return {
        props: {
          size: "size is removed.",
        },
      };
    },
    handleWrapperClick() {
      if (!this.closeOnClickModal) return;
      this.handleClose();
    },
    handleClose() {
      if (typeof this.beforeClose === "function") {
        this.beforeClose(this.hide);
      } else {
        this.hide();
      }
    },

    hide(cancel) {
      if (cancel !== false) {
        this.$emit("update:visible", false);
        this.$emit("close");
        this.closed = true;
      }
    },

    afterEnter() {
      this.$emit("opened");
    },
    afterLeave() {
      this.$emit("closed");
    },
  },

  mounted() {
    if (this.visible) {
      this.rendered = true;
      // this.open();
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
        // document.getElementById("v-modal").appendChild(this.$el);
      }
    }
  },

  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
      // document.getElementById("v-modal").removeChild(this.$el);
    }
  },
};
</script>
