import ApiService from "@/core/services/ApiService";
import { generateId } from "@/core/utils/util";

export const namespaced = true;

export const state = () => ({
  available_rooms: [],
  alocate_rooms: [],
  selected_mode: "",
  reservation_data: [],
  reservation_form_data: {},
  selected_reservation: {},
  disable_reservation: false,
  show_facility_alert: false,
  disable_editable: false,
  timing_header_search: "",
  timing_header_date: "",
  timing_today_uiud: "",
  visible_reservation_modal: false
});
export const getters = {
  getAvailbleRooms(state) {
    return state.available_rooms;
  },
  getAlocateRooms(state) {
    return state.alocate_rooms;
  },
  getReservationStatus(state) {
    return state.disable_reservation;
  },
};
export const mutations = {
  SET_VISIBLE_RESERVATION_MODAL(state, payload) {
    state.visible_reservation_modal = payload;
  },
  SET_TIMING_DATE_VALUE(state, payload) {
    state.timing_header_date = payload;
  },
  SET_TIMING_SEARCH_VALUE(state, payload) {
    state.timing_header_search = payload;
  },
  SET_TIMING_TODAY_DATE(state, payload) {
    state.timing_today_uiud = payload;
  },

  SET_AVAILABLE_ROOMS(state, payload) {
    state.available_rooms = payload;
  },
  SET_RESERVATION_MODE(state, payload) {
    state.selected_mode = payload;
  },
  SET_RESERVATION_DATA(state, payload) {
    state.reservation_data = payload;
  },
  SET_RESERVATION_FORM_DATA(state, payload) {
    state.reservation_form_data = payload;
  },
  SET_RESERVATION_STATUS(state, payload) {
    state.disable_reservation = payload;
  },
  SET_SELECTED_RESERVATION(state, payload) {
    state.selected_reservation = payload;
  },
  SET_FACILITY_ALERT(state, payload) {
    state.show_facility_alert = payload;
  },
  SET_ALOCATE_ROOMS(state, payload) {
    state.alocate_rooms = payload;
  },
  SET_EDITABLE_STATUS(state, payload) {
    state.disable_editable = payload;
  },

};

export const actions = {

  async setVisibleReservationModal({ commit, state }, payload) {
    try {
      commit("SET_VISIBLE_RESERVATION_MODAL", payload);
    } catch (error) {
      return error;
    }
  },
  async jumpToTodayDate({ commit, state }, payload) {
    try {
      commit("SET_TIMING_TODAY_DATE", generateId());
    } catch (error) {
      return error;
    }
  },

  async setTimingDateValue({ commit, state }, payload) {
    try {
      commit("SET_TIMING_DATE_VALUE", payload);
    } catch (error) {
      return error;
    }
  },
  async setTimingSearchValue({ commit, state }, payload) {
    try {
      commit("SET_TIMING_SEARCH_VALUE", payload);

    } catch (error) {
      return error;
    }
  },
  async setReservationMode({ commit, state }, payload) {
    try {
      commit("SET_RESERVATION_MODE", payload);
    } catch (error) {
      return error;
    }
  },
  async setReservationDisableStatus({ commit, state }, payload) {
    try {
      commit("SET_EDITABLE_STATUS", payload);
    } catch (error) {
      return error;
    }
  },
  async setSelectedReservation({ commit, state }, payload) {
    try {
      const selected_res = state.reservation_data.find(
        (res, i) => res._id == payload._id
      );
      commit("SET_EDITABLE_STATUS", selected_res.status == 'checkedOut'
        || selected_res.status == 'roomChanged'
        || selected_res.status == 'cancelled'
        || selected_res.status == 'cancelledCheckIn'
      )
      commit("SET_SELECTED_RESERVATION", selected_res);
    } catch (error) {
      return error;
    }
  },
  async setSelectedRes({ commit, state }, payload) {
    try {
      commit("SET_SELECTED_RESERVATION", payload);
    } catch (error) {
      return error;
    }
  },
  async setReservationStatus({ commit, state }, payload) {
    try {
      commit("SET_RESERVATION_STATUS", payload);
    } catch (error) {
      return error;
    }
  },

  async setReservationData({ commit, state }, payload) {
    try {
      commit("SET_RESERVATION_DATA", payload);
    } catch (error) {
      return error;
    }
  },

  async setReservationFormData({ commit, state }, payload) {
    try {
      commit("SET_RESERVATION_FORM_DATA", payload);
    } catch (error) {
      return error;
    }
  },
  async setFacilityAlert({ commit, state }, payload) {
    try {
      commit("SET_FACILITY_ALERT", payload);
    } catch (error) {
      return error;
    }
  },
  async checkAvailability({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`reservations/available`, payload);
      commit("SET_AVAILABLE_ROOMS", data.data);
      commit("SET_ALOCATE_ROOMS", []);
      return data;
    } catch (error) {
      return error;
    }
  },
  async cancelReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`reservations/cancel/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },
  async automaticAllocation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`reservations/alocate`, payload);
      commit("SET_AVAILABLE_ROOMS", data.data?.available);
      commit("SET_ALOCATE_ROOMS", data.data?.alocate);
      return data;
    } catch (error) {
      return error;
    }
  },
  async individualReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`reservations`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async groupReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`reservations/group`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`reservations/group/${payload}`);
      commit("SET_RESERVATION_DATA", data?.data);
    } catch (error) {
      return error;
    }
  },
  async fetchReservationDocs({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `guests/images/${payload.guest_id}/${payload.label_id}`
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async finalizeCheckout({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `reservations/finalize/${payload.id}`, payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async extendCheckoutTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `reservations/extendTime/${payload.id}`, payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async changeRoom({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        `reservations/changeRoom`, payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async checkoutReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `reservations/checkout/${payload.id}`, payload
      );
      commit("SET_EDITABLE_STATUS", true)
      return data;
    } catch (error) {
      return error;
    }
  },
  async deleteGuestDoc({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`guests/image/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateReservation({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `reservations/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async searchGuest({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `guests/${payload.labelId}/${payload.guest}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateGuest({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        `guests/${payload.labelId}/${payload.ID}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateGuestDocument({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`guests/image`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
};
