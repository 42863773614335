import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  timezones: [],
  currencies: [],
  owners: [],
  owner: {},
  objects: [],
  object: {},
  countries: [],
  cities: [],
  bars: [],
  products: [],
  rooms: [],
  facility_groups: [],
  facilities: [],
  selected_label: {},
  page_subtitle: "",
  active_beds: "",
  owner_objects: [],
  object_files: [],
  object_owners: [],
  selected_currency: "",
  active_cities: [],
  unsave_alert: false,
  currencyLess: false,
});

export const getters = {
  getObjects(state) {
    return state.objects;
  },
  getOwners(state) {
    return state.owners;
  },
  getBars(state) {
    return state.bars;
  },
  getProducts(state) {
    return state.products;
  },
  getRooms(state) {
    return state.rooms;
  },
  getSelectedLabel(state) {
    return state.selected_label;
  },
  getActiveBeds(state) {
    return state.active_beds;
  },
  getFacilityGroups(state) {
    return state.facility_groups;
  },
  getOwnerObjects(state) {
    return state.owner_objects;
  },
  getObjectFiles(state) {
    return state.object_files;
  },
  getObjectOwners(state) {
    return state.object_owners;
  },
  getCountries(state) {
    return state.countries;
  },
  getCities(state) {
    return state.cities;
  },
  getActiveCities(state) {
    return state.active_cities;
  },
  getSelectedCurrency(state) {
    return state.selected_currency;
  },
};

export const mutations = {
  SET_UNSAVE_ALERT(state, payload) {
    state.unsave_alert = payload;
  },
  SET_TIMEZONES(state, payload) {
    state.timezones = payload;
  },
  SET_CURRENCIES(state, payload) {
    state.currencies = payload;
  },
  SET_OWNERS(state, payload) {
    state.owners = payload;
  },
  SET_OWNER(state, payload) {
    state.owner = payload;
  },
  SET_OBJECTS(state, payload) {
    state.objects = payload;
  },
  SET_OBJECT(state, payload) {
    state.object = payload;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload;
  },
  SET_CITIES(state, payload) {
    state.cities = payload;
  },
  SET_BARS(state, payload) {
    state.bars = payload;
  },
  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },

  SET_ROOMS(state, payload) {
    state.rooms = payload;
  },
  SET_SELECTED_LABEL(state, payload) {
    state.selected_label = payload;

    if (!state.selected_currency) {
      state.selected_currency =
        state.selected_label.currency.mainCurrency.symbol;
    }
  },
  SET_PAGE_SUBTITLE(state, payload) {
    state.page_subtitle = payload;
  },
  SET_ACTIVE_BEDS(state, payload) {
    state.active_beds = payload;
  },
  SET_FACILITY_GROUPS(state, payload) {
    state.facility_groups = payload;
  },
  SET_OWNER_OBJECTS(state, payload) {
    state.owner_objects = payload;
  },
  SET_OBJECT_FILES(state, payload) {
    state.object_files = payload;
  },
  SET_OBJECT_OWNERS(state, payload) {
    state.object_owners = payload;
  },
  SET_SELECTED_CURRENCY(state, payload) {
    state.selected_currency = payload;
  },
  SET_ACTIVE_CITIES(state, payload) {
    state.active_cities = payload;
  },
  SET_CURRENY_LESS(state, payload) {
    state.currencyLess = payload;
  },
};

export const actions = {
  async setCurrencyLess({ commit, state }, payload) {
    try {
      commit("SET_CURRENY_LESS", payload);
    } catch (error) {
      return error;
    }
  },
  async setCurrency({ commit, state }, payload) {
    try {
      commit("SET_SELECTED_CURRENCY", payload);
    } catch (error) {
      return error;
    }
  },

  async setUnsaveAlert({ commit, state }, payload) {
    try {
      commit("SET_UNSAVE_ALERT", payload);
    } catch (error) {
      return error;
    }
  },

  async ownerAssignObject({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`objects/owner/group`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async objectAssignOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`objects/owner/group`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async objectAssignFile({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`objects/image`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async deleteObjectBed({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        `objects/beds/${payload.object}/${payload.bed}`
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async createObjectBed({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`objects/beds`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchActiveBeds({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`beds/active`);
      commit("SET_ACTIVE_BEDS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchLabelById({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`labels/byId/${payload}`);
      commit("SET_SELECTED_LABEL", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  setSelectedLabel({ commit, state }, payload) {
    commit("SET_SELECTED_LABEL", payload);
  },

  async setPageSubtitle({ commit, state }, payload) {
    try {
      commit("SET_PAGE_SUBTITLE", payload);
    } catch (error) {
      return error;
    }
  },

  async fetchCountries({ commit, state }) {
    try {
      const { data } = await ApiService.get("countries");
      commit("SET_COUNTRIES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchCitiesByCountry({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`cities/by_country/${payload}`);
      commit("SET_CITIES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchActiveCities({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`cities/active`);
      commit("SET_ACTIVE_CITIES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  // timezones
  async fetchTimezones({ commit, state }) {
    try {
      const { data } = await ApiService.get("timezones");
      commit("SET_TIMEZONES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  // currencies

  async fetchCurrencies({ commit, state }) {
    try {
      const { data } = await ApiService.get("currencies/active");
      commit("SET_CURRENCIES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  // owners

  async createOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("owners", payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`owners/${payload.id}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`owners/${payload}`);
      commit("SET_OWNER", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchOwners({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`owners/label/${payload}`);
      commit("SET_OWNERS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async deleteOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`owners/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  // objects

  async createObject({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("objects", payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateObject({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`objects/${payload.id}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchObject({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`objects/${payload}`);
      commit("SET_OBJECT", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchObjects({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`objects/label/${payload}`);
      commit("SET_OBJECTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async deleteObject({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`objects/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  // label configuration
  async updateLabelTime({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `labels/time/${payload.label_id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateLabelCurrency({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `labels/currency/${payload.label_id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchBars({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`bars/label/${payload}`);
      commit("SET_BARS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchAllBars({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`bars`);
      commit("SET_BARS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async createLabelBar({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`bars`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteBar({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`bars/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  // bar products

  async fetchProducts({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`product-bar/bar/${payload}`);
      commit("SET_PRODUCTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async createProduct({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`product-bar`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteProduct({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`product-bar/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  // rooms

  async fetchRooms({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`rooms/label/${payload}`);
      commit("SET_ROOMS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchAssignmentRooms({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`assignments/allRooms/${payload}`);
      commit("SET_ROOMS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async updateRoom({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`rooms/${payload.room}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async updateGroupRoom({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`rooms`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchFacilityGroups({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`facilities-group`);
      commit("SET_FACILITY_GROUPS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchOwnerObjects({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `objects/owner/byOwnerId/${payload}`
      );
      commit("SET_OWNER_OBJECTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteOwnerObjects({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`objects/owner/${payload}`);
      // commit("SET_OWNER_OBJECTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchObjectFiles({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`objects/images/${payload}`);
      commit("SET_OBJECT_FILES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteObjectPciture({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`objects/image/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchObjectOwners({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `objects/owner/byObjectId/${payload}`
      );
      commit("SET_OBJECT_OWNERS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteObjectOwner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`objects/owner/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async updateOwnerShare({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `objects/owner/${payload.id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
