export default {
  path: "/panel/admin",
  redirect: "/panel/admin/dashboard",
  meta: {
    auth: true,
    admin: true,
  },
  component: () =>
    import(/* webpackChunkName: "admin" */ "@/layouts/admin.vue"),

  children: [
    {
      path: "dashboard",
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "admin-dashboard" */ "@/views/admin/dashboard.vue"
        ),
    },

    {
      path: "countries",
      name: "panel countries index",
      component: () =>
        import(
          /* webpackChunkName: "countries-index" */ "@/views/admin/countries/index.vue"
        ),
    },
    {
      path: "countries/create",
      name: "panel countries create",
      component: () =>
        import(
          /* webpackChunkName: "countries-create" */ "@/views/admin/countries/create.vue"
        ),
    },
    {
      path: "countries/edit/:id",
      name: "panel countries edit",
      component: () =>
        import(
          /* webpackChunkName: "countries-edit" */ "@/views/admin/countries/edit.vue"
        ),
    },

    // cities

    {
      path: "cities",
      name: "panel cities index",
      component: () =>
        import(
          /* webpackChunkName: "cities-index" */ "@/views/admin/cities/index.vue"
        ),
    },
    {
      path: "cities/create",
      name: "panel cities create",
      component: () =>
        import(
          /* webpackChunkName: "cities-create" */ "@/views/admin/cities/create.vue"
        ),
    },
    {
      path: "cities/edit/:id",
      name: "panel cities edit",
      component: () =>
        import(
          /* webpackChunkName: "cities-edit" */ "@/views/admin/cities/edit.vue"
        ),
    },

    // users

    {
      path: "users",
      name: "panel users index",
      component: () =>
        import(
          /* webpackChunkName: "users-index" */ "@/views/admin/users/index.vue"
        ),
    },
    {
      path: "users/create",
      name: "panel users create",
      component: () =>
        import(
          /* webpackChunkName: "users-create" */ "@/views/admin/users/create.vue"
        ),
    },
    {
      path: "users/edit/:id",
      name: "panel users edit",
      component: () =>
        import(
          /* webpackChunkName: "users-edit" */ "@/views/admin/users/edit.vue"
        ),
    },

    // about persons

    {
      path: "about/persons",
      name: "panel about index",
      component: () =>
        import(
          /* webpackChunkName: "about-index" */ "@/views/admin/about/persons/index.vue"
        ),
    },
    {
      path: "about/persons/create",
      name: "panel about create",
      component: () =>
        import(
          /* webpackChunkName: "about-create" */ "@/views/admin/about/persons/create.vue"
        ),
    },
    {
      path: "about/persons/edit/:id",
      name: "panel about edit",
      component: () =>
        import(
          /* webpackChunkName: "about-edit" */ "@/views/admin/about/persons/edit.vue"
        ),
    },

    // about general
    {
      path: "about/general",
      name: "panel about general index",
      component: () =>
        import(
          /* webpackChunkName: "about-general-index" */ "@/views/admin/about/general/index.vue"
        ),
    },

    {
      path: "about/general/edit/:id",
      name: "panel about general edit",
      component: () =>
        import(
          /* webpackChunkName: "about-general-edit" */ "@/views/admin/about/general/edit.vue"
        ),
    },

    // hardware request file
    {
      path: "hardware/file",
      name: "panel hardware file index",
      component: () =>
        import(
          /* webpackChunkName: "hardware-file-index" */ "@/views/admin/hardware/file/index.vue"
        ),
    },
    // hardware request file
    {
      path: "hardware/file/create",
      name: "panel hardware file create",
      component: () =>
        import(
          /* webpackChunkName: "hardware-file-create" */ "@/views/admin/hardware/file/create.vue"
        ),
    },

    {
      path: "hardware/file/edit",
      name: "panel hardware file edit",
      component: () =>
        import(
          /* webpackChunkName: "hardware-file-edit" */ "@/views/admin/hardware/file/edit.vue"
        ),
    },

    {
      path: "facilities/group",
      name: "panel facilities group index",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-index" */ "@/views/admin/facilities/group/index.vue"
        ),
    },
    {
      path: "facilities/group/create",
      name: "panel facilities group create",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-create" */ "@/views/admin/facilities/group/create.vue"
        ),
    },
    {
      path: "facilities/group/edit/:id",
      name: "panel facilities group edit",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-edit" */ "@/views/admin/facilities/group/edit.vue"
        ),
    },
    {
      path: "facilities/group/facilities/:id",
      name: "panel facilities group facilities",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-facilities" */ "@/views/admin/facilities/index.vue"
        ),
    },
    {
      path: "facilities/group/facilities/:id/create",
      name: "panel facilities group facilities create",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-facilities-create" */ "@/views/admin/facilities/create.vue"
        ),
    },
    {
      path: "facilities/group/facilities/:id/edit/:facility",
      name: "panel facilities group facilities edit",
      component: () =>
        import(
          /* webpackChunkName: "facilities-group-facilities-edit" */ "@/views/admin/facilities/edit.vue"
        ),
    },

    {
      path: "landing/pages",
      name: "panel landing pages index",
      component: () =>
        import(
          /* webpackChunkName: "landing-index" */ "@/views/admin/landing/index.vue"
        ),
    },
    {
      path: "landing/pages/create",
      name: "panel landing pages create",
      component: () =>
        import(
          /* webpackChunkName: "landing-create" */ "@/views/admin/landing/create.vue"
        ),
    },
    {
      path: "landing/pages/edit/:id",
      name: "panel landing pages edit",
      component: () =>
        import(
          /* webpackChunkName: "landing-edit" */ "@/views/admin/landing/edit.vue"
        ),
    },

    {
      path: "static/pages",
      name: "panel static pages index",
      component: () =>
        import(
          /* webpackChunkName: "statics-index" */ "@/views/admin/statics/index.vue"
        ),
    },

    {
      path: "static/pages/create",
      name: "panel static pages create",
      component: () =>
        import(
          /* webpackChunkName: "statics-create" */ "@/views/admin/statics/create.vue"
        ),
    },

    {
      path: "static/pages/edit/:id",
      name: "panel static pages edit",
      component: () =>
        import(
          /* webpackChunkName: "statics-edit" */ "@/views/admin/statics/edit.vue"
        ),
    },

    // timezones

    {
      path: "timezones",
      name: "panel timezones index",
      component: () =>
        import(
          /* webpackChunkName: "timezones-index" */ "@/views/admin/timezones/index.vue"
        ),
    },
    {
      path: "timezones/create",
      name: "panel timezones create",
      component: () =>
        import(
          /* webpackChunkName: "timezones-create" */ "@/views/admin/timezones/create.vue"
        ),
    },
    {
      path: "timezones/edit/:id",
      name: "panel timezones edit",
      component: () =>
        import(
          /* webpackChunkName: "timezones-edit" */ "@/views/admin/timezones/edit.vue"
        ),
    },

    // currencies

    {
      path: "currencies",
      name: "panel currencies index",
      component: () =>
        import(
          /* webpackChunkName: "currencies-index" */ "@/views/admin/currencies/index.vue"
        ),
    },
    {
      path: "currencies/create",
      name: "panel currencies create",
      component: () =>
        import(
          /* webpackChunkName: "currencies-create" */ "@/views/admin/currencies/create.vue"
        ),
    },
    {
      path: "currencies/edit/:id",
      name: "panel currencies edit",
      component: () =>
        import(
          /* webpackChunkName: "currencies-edit" */ "@/views/admin/currencies/edit.vue"
        ),
    },

    // beds

    {
      path: "beds",
      name: "panel beds index",
      component: () =>
        import(
          /* webpackChunkName: "beds-index" */ "@/views/admin/beds/index.vue"
        ),
    },
    {
      path: "beds/create",
      name: "panel beds create",
      component: () =>
        import(
          /* webpackChunkName: "beds-create" */ "@/views/admin/beds/create.vue"
        ),
    },
    {
      path: "beds/edit/:id",
      name: "panel beds edit",
      component: () =>
        import(
          /* webpackChunkName: "beds-edit" */ "@/views/admin/beds/edit.vue"
        ),
    },

    // appointments requests

    {
      path: "appointments/requests",
      name: "panel appointments requests index",
      component: () =>
        import(
          /* webpackChunkName: "appointments-requests-index" */ "@/views/admin/appointments/requests/index.vue"
        ),
    },

    // appointments dates

    {
      path: "appointments/dates",
      name: "panel appointments dates index",
      component: () =>
        import(
          /* webpackChunkName: "appointments-dates-index" */ "@/views/admin/appointments/dates/index.vue"
        ),
    },
    {
      path: "appointments/dates/create",
      name: "panel appointments dates create",
      component: () =>
        import(
          /* webpackChunkName: "appointments-dates-create" */ "@/views/admin/appointments/dates/create.vue"
        ),
    },
    {
      path: "appointments/dates/edit/:id",
      name: "panel appointments dates edit",
      component: () =>
        import(
          /* webpackChunkName: "appointments-dates-edit" */ "@/views/admin/appointments/dates/edit.vue"
        ),
    },

    // appointments times

    {
      path: "appointments/times",
      name: "panel appointments times index",
      component: () =>
        import(
          /* webpackChunkName: "appointments-times-index" */ "@/views/admin/appointments/times/index.vue"
        ),
    },
    {
      path: "appointments/times/create",
      name: "panel appointments times create",
      component: () =>
        import(
          /* webpackChunkName: "appointments-times-create" */ "@/views/admin/appointments/times/create.vue"
        ),
    },
    {
      path: "appointments/times/edit/:id",
      name: "panel appointments times edit",
      component: () =>
        import(
          /* webpackChunkName: "appointments-times-edit" */ "@/views/admin/appointments/times/edit.vue"
        ),
    },

    // beds

    {
      path: "packages",
      name: "panel packages index",
      component: () =>
        import(
          /* webpackChunkName: "packages-index" */ "@/views/admin/packages/index.vue"
        ),
    },
    {
      path: "packages/edit/:id",
      name: "panel packages edit",
      component: () =>
        import(
          /* webpackChunkName: "packages-edit" */ "@/views/admin/packages/edit.vue"
        ),
    },

    // hardware requests

    {
      path: "hardware/requests",
      name: "panel hardware requests index",
      component: () =>
        import(
          /* webpackChunkName: "hardware-requests-index" */ "@/views/admin/hardware/requests/index.vue"
        ),
    },
    {
      path: "payments",
      name: "panel payments index",
      component: () =>
        import(
          /* webpackChunkName: "hardware-requests-index" */ "@/views/admin/payments/index.vue"
        ),
    },
  ],
};
