export default {
    path: "/invoices",
    component: () =>
        import(
        /* webpackChunkName: "invoice-layout" */ "@/layouts/invoice.vue"
        ),
    children: [
        {
            path: ":label/print/:group/:currency/:room?",
            name: "label management invoices detail",
            component: () =>
                import(
            /* webpackChunkName: "label-Invoices-detail" */ "@/views/management/InvoiceDetail.vue"
                ),
        },
    ],
};
