import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  personals: [],
  personal: {},
});

export const getters = {
  getPersonals(state) {
    return state.personals;
  },
  getPersonal(state) {
    return state.personal;
  },
};

export const mutations = {
  SET_PERSONALS(state, payload) {
    state.personals = payload;
  },
  SET_PERSONAL(state, payload) {
    state.personal = payload;
  },
};

export const actions = {
  async fetchPersonal({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`user/${payload}`);
      commit("SET_PERSONAL", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchPersonals({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`user/personal`);
      commit("SET_PERSONALS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchPersonalsByLabel({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`user/bylabelId/${payload}`);
      commit("SET_PERSONALS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async checkLabel({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`user/personal/checkLabel/${payload.id}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async createPersonal({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`user`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async updatePersonal({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`user/${payload.id}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deletePersonal({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`user/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },
};
