<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import HXLoader from "@/components/app/Loader.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    HXLoader,
    ProfileDialog: () => import("@/components/user/ProfileDialog.vue"),
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions("Auth", ["verifyAuth", "fetchPrivacyPolicy", "fetchTerms"]),
    ...mapActions("Pricing", ["fetchAllLabels"]),
    ...mapActions("General", ["setGeneralWsConnection"]),
    isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  },
  computed: {
    ...mapGetters("Auth", ["Authenticated"]),
  },
  mounted() {
    // let ws_connection = new WebSocket(process.env.VUE_APP_BASE_WS);
    // this.setGeneralWsConnection(ws_connection);
    this.fetchPrivacyPolicy(({ data }) => {}).catch(() => {});
    this.fetchTerms(({ data }) => {}).catch(() => {});

    if (this.Authenticated) {
      this.fetchAllLabels()
        .then((data) => {
          // this.labels = this.getLabels();
        })
        .catch(() => {});
    }

    if (this.isSafari()) {
    document.body.classList.add('safari-browser');
  }
  },
};
</script>

<style lang="scss">
@import "assets/css/tailwind.css";
@import "assets/scss/general.scss";
</style>
<style>
.safari-browser{
  font-weight: 500 !important;
}
</style>