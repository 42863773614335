import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
    ws_connection: {},
});

export const getters = {

};

export const mutations = {
    SET_WS_CONNECTION(state, payload) {
        state.ws_connection = payload;
    },

};

export const actions = {
    async setGeneralWsConnection({ commit, state }, payload) {
        try {
            commit("SET_WS_CONNECTION", payload);
        } catch (error) {
            return error;
        }
    },

};
