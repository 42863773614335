import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    cities: [],
    city: {},
})

export const getters = {
    getCities(state) {
        return state.cities
    },
    getCity(state) {
        return state.city
    },
}

export const mutations = {
    SET_CITIES(state, payload) {
        state.cities = payload;
    },
    SET_CITY(state, payload) {
        state.city = payload;
    },
}

export const actions = {
    async fetchCity({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`cities/${payload}`)
            commit("SET_CITY", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchCities({ commit, state }) {
        try {
            const { data } = await ApiService.get("cities")
            commit("SET_CITIES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async handleCreateCity({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("cities", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleDeleteCity({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`cities/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleUpdateCity({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`cities/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}