import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  file: {},
});

export const getters = {
  getFile(state) {
    return state.file;
  },
};

export const mutations = {
  SET_FILE(state, payload) {
    state.file = payload;
  },
};

export const actions = {
  async fetchFile({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`hardware-request/download/file`);
      commit("SET_FILE", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async CreateFile({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        "hardware-request/download/file/hardware_file",
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },

  async updateFile({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        "hardware-request/download/file/hardware_file",
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
