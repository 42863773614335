export default {
  path: "/timing",
  meta: {
    auth: true,
  },
  component: () =>
    import(/* webpackChunkName: "admin" */ "@/layouts/timing.vue"),
  children: [
    {
      path: "panel/:id",
      name: "label timing panel",
      component: () =>
        import(
          /* webpackChunkName: "labeel-timing" */ "@/views/timing/panel.vue"
        ),
    },


  ],
};
