import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { mapActions, mapGetters, mapState } from "vuex";

Vue.use(VueRouter);

// modules

import landing from "@/router/landing";
import configuration from "@/router/configuration";
import admin from "@/router/admin";
import auth from "@/router/auth";
import timing from "@/router/timing";
import reservation from "@/router/reservation";
import setting from "@/router/setting";
import management from "@/router/management";
import dashboard from "@/router/dashboard/dashboard";
import hardware from "@/router/hardware";
import invoice from "@/router/invoice";

const routes = [
  landing,
  configuration,
  admin,
  auth,
  timing,
  reservation,
  setting,
  management,
  dashboard,
  hardware,
  invoice
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

let verify_user = true

router.beforeEach(async (to, from, next) => {


  if (verify_user) await store.dispatch("Auth/verifyAuth")

  verify_user = false


  let access_routes = [
    "label management guests",
    "label management booking",
    "label management invoice",
    "label management partners",
    "label setting bar"
  ]
  let denied_permission = access_routes.some((item, index) => {
    return to.name.indexOf(item) !== -1;
  });
  if (store.state.Auth.user.type == "DeskManagerN2" && denied_permission) {
    next({ name: "packages" });
  }

  if (to.meta.guest && store.state.Auth.isAuthenticated) {
    next({ name: "pricing" });
  }
  if (
    (to.meta.auth || to.matched.some((parent) => parent.meta.auth)) &&
    !store.state.Auth.isAuthenticated
  ) {
    next({ name: "packages" });
  }

  if (to.matched.some((parent) => parent.meta.admin) && store.state.Auth.user.type != "Admin") {
    next({ name: "packages" });
  }

  next();
});

export default router;
