import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import InlineSvg from "vue-inline-svg";
import VueMeta from "vue-meta";
import store from "@/store";
import ApiService from "@/core/services/ApiService";
import VueTheMask from "vue-the-mask";
import i18n from "@/core/plugins/i18n";
// import VueI18n from 'vue-i18n'
import HxMessageBox from "@/components/base/message-box";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import Loading from "@/components/base/loading";
Vue.use(VueTheMask);
Vue.use(FloatingVue)
// import dom from "@/core/mixins/dom";
Vue.use(VueMeta);
// Vue.use(i18n)
Vue.config.productionTip = false;
Vue.component("inline-svg", InlineSvg);

// Vue.mixin(dom(Vue))

ApiService.init(Vue);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = HxMessageBox;
Vue.prototype.$alert = HxMessageBox.alert;
Vue.prototype.$confirm = HxMessageBox.confirm;
Vue.prototype.$prompt = HxMessageBox.prompt;
// Vue.prototype.$socket = new WebSocket(process.env.VUE_APP_BASE_WS);

Vue.directive("currency", {
  bind: function (el, binding, vnode) {
    if (Object.values(el.classList).includes("hx-input")) {
      let exists = el
        .closest("div.hx-input")
        .querySelector("input.hx-input__inner");
      if (exists) {
        let exchange_ratio =
          store.state.LabelConfig.selected_label?.currency?.exchangeRatio;
        exchange_ratio = exchange_ratio ? exchange_ratio : 1;
        store.watch(
          (state) => state.LabelConfig.selected_currency,
          (curCurrency) => {
            store.state.LabelConfig.selected_currency ==
              store.state.LabelConfig.selected_label?.currency?.secondCurrency
                ?.symbol
              ? (exists.value = Number(exists.value * exchange_ratio).toFixed(
                2
              ))
              : (exists.value = Number(exists.value / exchange_ratio).toFixed(
                2
              ));

            vnode.elm.dispatchEvent(new CustomEvent("input"));
          }
        );
      }
    } else {
      let exchange_ratio =
        store.state.LabelConfig.selected_label?.currency?.exchangeRatio;

      exchange_ratio = exchange_ratio ? exchange_ratio : 1;

      store.watch(
        (state) => state.LabelConfig.selected_currency,
        (curCurrency) => {
          store.state.LabelConfig.selected_currency ==
            store.state.LabelConfig.selected_label?.currency?.secondCurrency
              ?.symbol
            ? (vnode.elm.innerHTML = Number(vnode.elm.innerHTML * exchange_ratio).toFixed(2))
            : (vnode.elm.innerHTML = Number(vnode.elm.innerHTML / exchange_ratio).toFixed(2));
        }
      );
    }
  },
  update: function (el, binding, vnode) { },
  inserted: function (el, binding, vnode) {
    vnode.elm.dispatchEvent(new CustomEvent("input"));
  },
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
