import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  guests: [],
});

export const getters = {
  getGuests(state) {
    return state.guests;
  },
};

export const mutations = {
  SET_GUESTS(state, payload) {
    state.guests = payload;
  },
};

export const actions = {
  async fetchGuests({ commit, state }, payload) {
    try {
      let url = `guests/label/${payload.id}`
      if (payload.status == "successful") {
        url = `guests/label/${payload.id}/true`
      }
      const { data } = await ApiService.get(url);
      commit("SET_GUESTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateGuests({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `guests/${payload.guestId}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
