export default {
    path: "/",
    redirect: "packages",
    component: () =>
        import(/* webpackChunkName: "dashboard-layout" */ "@/layouts/landing/dashboard.vue"),
    children: [
        {
            path: "dashboard",
            name: "landing-dashboard",
            component: () =>
                import(
                /* webpackChunkName: "landing-dashboard" */ "@/views/landing/dashboard/dashboard.vue"
                ),
        },
    ]
}