<template>
  <div>
    <hx-dialog
      :close-on-click-modal="false"
      class="dialog-unset-padding"
      custom-class=""
      title=""
      :visible="value"
      @close="handleBeforeClose()"
    >
      <div class="flex flex-col justify-center items-center px-8 py-14">
        <div class="lds-dual-ring"></div>
        <div class="mt-3">Please be patient ..</div>
      </div>
    </hx-dialog>
  </div>
</template>

<script>
import HxDialog from "@/components/base/dialog";
export default {
  name: "PageLoader",

  components: {
    HxDialog,
    // HxDialog: () => import("@/components/base/dialog"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleBeforeClose() {
      this.$emit("input", false);
    },
  },
};
</script>
