import ApiService from "@/core/services/ApiService";
export const namespaced = true;

export const state = () => ({
  header_title: "",
  header_desc: "",
  title: "",
  hardware_total: 0,
  labels: [],
  available_labels: [],
  package: {},
  packages: [],
  selected_package: {},
  selected_hardwares: [],
  selected_label: {},
  active_step: 0,
  selected_interval: "monthly",
  hardware: {
    smartDoor: false,
    fireAlarm: false,
    video: false,
  },
  invoice: {},
  interval_percent: 35,
  disable_interval: false,
  disable_package_hardwares: false,
  required_hardware_notif: false,
  companyInfo: {}
});

export const getters = {
  title(state) {
    return state.title;
  },
  getLabels(state) {
    return state.labels;
  },
  availableLabels(state) {
    return state.available_labels;
  },
  getPackges(state) {
    return state.packages;
  },
  getPackge(state) {
    return state.package;
  },
  getSelectedpackage(state) {
    return state.selected_package;
  },
};

export const mutations = {
  SET_COMPANY_INFO(state, payload) {
    state.companyInfo = payload;
  },
  SET_HEADER_UTILS(state, payload) {
    state.header_title = payload.title;
    state.header_desc = payload.description;
  },
  SET_TITLE(state, payload) {
    state.title = payload;
  },
  SET_LABELS(state, payload) {
    state.labels = payload;
  },
  SET_AVAILABLE_LABELS(state, payload) {
    state.available_labels = payload;
  },
  SET_PACKAGES(state, payload) {
    state.selected_package = {}
    state.disable_interval = false
    state.disable_package_hardwares = false
    state.required_hardware_notif = false
    state.packages = payload;
    state.packages.map((item, index) => {
      item.calculated_price = item.price;
      item.yearly_price = (
        Number(item.price * 12) -
        Number(
          item.price *
          12 *
          (state.interval_percent / 100)
        )
      ).toFixed(2);
      Object.values(item.hardware).forEach((hardware, key) => {
        hardware.calculated_price = hardware.price;
      });
    });
  },

  SET_PACKAGE(state, payload) {
    state.package = payload;

    state.selected_package = payload;
    state.selected_package.calculated_price = state.selected_package.price;
    state.selected_package.yearly_price = (
      Number(state.selected_package.price * 12) -
      Number(
        state.selected_package.price *
        12 *
        (state.interval_percent / 100)
      )
    ).toFixed(2);

    Object.values(state.selected_package.hardware).forEach((hardware, key) => {
      hardware.calculated_price = hardware.price;
    });

    if (
      state.selected_package.type == "primary" ||
      state.selected_package.type == "premium"
    ) {
      if (state.selected_interval == "yearly") {
        Object.values(state.selected_package.hardware).forEach(
          (hardware, key) => {
            hardware.price = (
              Number(hardware.calculated_price * 12) -
              Number(
                hardware.calculated_price * 12 * (state.interval_percent / 100)
              )
            ).toFixed(2);
          }
        );

        state.selected_package.price = (
          Number(state.selected_package.calculated_price * 12) -
          Number(
            state.selected_package.calculated_price *
            12 *
            (state.interval_percent / 100)
          )
        ).toFixed(2);
      } else {
        state.selected_package.price = Number(
          state.selected_package.calculated_price
        ).toFixed(2);

        Object.values(state.selected_package.hardware).forEach(
          (hardware, key) => {
            hardware.price = Number(hardware.calculated_price).toFixed(2);
          }
        );
      }
    }


    if (state.selected_package.type == "premium") {
      const selectedHardwares = Object.fromEntries(
        state.selected_hardwares.map((key) => [key, state.selected_package.hardware[key]])
      );

      const totalPrice = Object.values(selectedHardwares).reduce((acc, item) => {
        return acc + Number(item.price);
      }, 0);

      if (state.selected_interval == "yearly") {
        state.selected_package.price = Number(Number(state.selected_package.yearly_price) + totalPrice).toFixed(2)
      } else {
        state.selected_package.price = Number(Number(state.selected_package.calculated_price) + totalPrice).toFixed(2)
      }

    }

    if (state.selected_package.type == "free") {
      state.disable_interval = true;
    } else {
      state.disable_interval = false;
    }
  },

  SET_SELECTED_LABEL(state, payload) {
    state.selected_label = payload;
  },

  SET_SELECTED_PACKAGE(state, payload) {
    state.selected_package = payload;
  },

  SET_HARDWARE(state, payload) {
    state.hardware = payload;
  },

  SET_SELECTED_HARDWARE(state, payload) {

    state.selected_hardwares = payload;

    if (state.selected_hardwares.length >= 1) {
      const premium_package = state.packages.find((pk, i) => pk.type == "premium") || state.selected_package

      const selectedHardwares = Object.fromEntries(
        state.selected_hardwares.map((key) => [key, premium_package.hardware[key]])
      );
      let totalPrice = 0


      if (premium_package.type == "premium") {
        totalPrice = Object.values(selectedHardwares).reduce((acc, item) => {
          return acc + Number(item.price);
        }, 0);
      }

      let is_premium = state.selected_package.type == "premium" ? totalPrice : 0



      if (state.selected_interval == "yearly") {
        premium_package.price = Number(Number(premium_package.yearly_price) + totalPrice).toFixed(2)
        if (state.selected_package) state.selected_package.price = Number(Number(state.selected_package.yearly_price) + is_premium).toFixed(2);
      } else {
        premium_package.price = Number(Number(premium_package.calculated_price) + totalPrice).toFixed(2)
        if (state.selected_package) state.selected_package.price = Number(Number(state.selected_package.calculated_price) + is_premium).toFixed(2);

      }

    }
  },

  SET_INVOICE(state, payload) {
    state.invoice = payload;
  },

  SET_ACTIVE_STEP(state, payload) {
    state.active_step = payload;
    if (state.active_step >= 2 || state.selected_package.type == "free") {
      state.disable_interval = true;
      state.disable_package_hardwares = true;
    } else {
      state.disable_interval = false;
      state.disable_package_hardwares = false;
    }
  },
  SET_INTERVAL(state, payload) {

    let isYearly = payload === "yearly";

    for (const item of state.packages) {
      if (item.type !== "primary" && item.type !== "premium") {
        continue;
      }



      Object.values(item.hardware).forEach((hardware, key) => {
        const calculatedPrice = hardware.calculated_price;

        if (isYearly) {

          const yearlyPrice = Number(calculatedPrice * 12);
          const discountedPrice = Number(
            yearlyPrice - yearlyPrice * (state.interval_percent / 100)
          );
          hardware.price = discountedPrice.toFixed(2);
        } else {
          hardware.price = Number(calculatedPrice).toFixed(2);
        }
      });

      // if (item.type !== "premium") {
      //   item.price = item.yearly_price;
      //   continue;
      // }

      const selectedHardwares = Object.fromEntries(
        state.selected_hardwares.map((key) => [key, item.hardware[key]])
      );

      const totalPrice = Object.values(selectedHardwares).reduce((acc, item) => {
        return acc + Number(item.price);
      }, 0);

      item.price = Number(
        isYearly ? Number(item.yearly_price) + totalPrice : Number(item.calculated_price) + totalPrice
      ).toFixed(2);
    }


    if (state.selected_package) {
      if (
        state.selected_package.type == "primary"
      ) {
        if (payload == "yearly") {
          Object.values(state.selected_package.hardware).forEach(
            (hardware, key) => {
              hardware.price = (
                Number(hardware.calculated_price * 12) -
                Number(
                  hardware.calculated_price *
                  12 *
                  (state.interval_percent / 100)
                )
              ).toFixed(2);
            }
          );
          state.selected_package.price = state.selected_package.yearly_price
        } else {
          state.selected_package.price = Number(
            state.selected_package.calculated_price
          ).toFixed(2);

          Object.values(state.selected_package.hardware).forEach(
            (hardware, key) => {
              hardware.price = Number(hardware.calculated_price).toFixed(2);
            }
          );
        }
      }

      if (state.selected_package.type == "premium") {
        const selectedHardwares = Object.fromEntries(
          state.selected_hardwares.map((key) => [key, state.selected_package.hardware[key]])
        );
        Object.values(state.selected_package.hardware).forEach((hardware, key) => {
          const calculatedPrice = hardware.calculated_price;

          if (isYearly) {

            let yearlyPrice = Number(calculatedPrice * 12);
            let discountedPrice = Number(
              yearlyPrice - yearlyPrice * (state.interval_percent / 100)
            );
            hardware.price = discountedPrice.toFixed(2);
          } else {
            hardware.price = Number(calculatedPrice).toFixed(2);
          }
        });

        const totalPrice = Object.values(selectedHardwares).reduce((acc, item) => {
          return acc + Number(item.price);
        }, 0);

        if (state.selected_interval == "yearly") {
          state.selected_package.price = Number(Number(state.selected_package.yearly_price) + totalPrice).toFixed(2)
        } else {
          state.selected_package.price = Number(Number(state.selected_package.calculated_price) + totalPrice).toFixed(2)
        }

      }
    }



  },

  SET_SELECTED_INTERVAL(state, payload) {
    state.selected_interval = payload;
  },

  SET_HARDWARE_REQUIRED(state, payload) {
    state.required_hardware_notif = payload;
  },
};

export const actions = {
  async fetchCompanyInfo({ commit }, payload) {
    try {
      const { data } = await ApiService.get(`user-package/companyInfo/${payload}`);
      commit("SET_COMPANY_INFO", data.data);
      return data;
    } catch (error) { }
  },
  async setHeaderUtils({ commit }, payload) {
    commit("SET_HEADER_UTILS", payload);
  },
  async set({ commit }, payload) {
    commit("SET_TITLE", payload);
  },
  async setHardwareNotif({ commit }, payload) {
    commit("SET_HARDWARE_REQUIRED", payload);
  },
  async setActiveStep({ commit }, payload) {
    commit("SET_ACTIVE_STEP", payload);
  },

  async selectPackage({ commit }, payload) {
    commit("SET_SELECTED_PACKAGE", payload);
  },
  async selectLabel({ commit }, payload) {
    commit("SET_SELECTED_LABEL", payload);
  },
  async setSelectedHardware({ commit }, payload) {
    commit("SET_SELECTED_HARDWARE", payload);
  },

  async setSelectedInterval({ commit }, payload) {
    commit("SET_SELECTED_INTERVAL", payload);
  },

  async selectHardware({ commit }, payload) {
    commit("SET_HARDWARE", payload);
  },
  async setInvoice({ commit }, payload) {
    commit("SET_INVOICE", payload);
  },
  async fetchAllLabels({ commit }) {
    try {
      const { data } = await ApiService.get("labels");
      commit("SET_LABELS", data.data);
      return data;
    } catch (error) { }
  },
  async clearLabels({ commit }) {
    try {
      commit("SET_LABELS", []);
    } catch (error) { }
  },
  async fetchPackages({ commit, state }) {
    try {
      const { data } = await ApiService.get("package");
      commit("SET_PACKAGES", data.data);
      commit("SET_INTERVAL", state.selected_interval);
      return data;
    } catch (error) { }
  },
  async changeInterval({ commit }, payload) {
    try {
      commit("SET_SELECTED_INTERVAL", payload);
      commit("SET_INTERVAL", payload);
    } catch (error) { }
  },
  async fetchPackage({ commit }, payload) {
    try {
      const { data } = await ApiService.get(`package/${payload}`);
      commit("SET_PACKAGE", data.data);
      return data;
    } catch (error) { }
  },
  async createLabel({ commit }, payload) {
    try {
      const { data } = await ApiService.post("labels", payload);
      // commit("SET_AVAILABLE_LABELS", data);
      return data;
    } catch (error) { }
  },
};
