import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  payments: [],
  payment: {},
});

export const getters = {
  getPayments(state) {
    return state.payments;
  },
  getPayment(state) {
    return state.payment;
  },
};

export const mutations = {
  SET_PAYMENTS(state, payload) {
    state.payments = payload;
  },
  SET_PAYMENT(state, payload) {
    state.payment = payload;
  },
};

export const actions = {
  async fetchPayment({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`payment/${payload}`);
      commit("SET_PAYMENT", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchPayments({ commit, state }) {
    try {
      const { data } = await ApiService.get("user/unpaid/package");
      commit("SET_PAYMENTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deletePayment({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`user/unpaid/package/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async confirmPayment({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        `user-package/payment`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
