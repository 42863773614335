import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
    hardwares: [],
});

export const getters = {
    getHardwares(state) {
        return state.hardwares;
    },
};

export const mutations = {
    SET_HARDWARES(state, payload) {
        state.hardwares = payload;
    },
};

export const actions = {
    async fetchHardwares({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`mid-server/label/${payload}`);
            commit("SET_HARDWARES", data.data);
            return data;
        } catch (error) {
            return error;
        }
    },

    async addHardware({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post(`mid-server/addHardware`, payload);
            return data;
        } catch (error) {
            return error;
        }
    },

    async deleteHardware({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post(`mid-server/deleteHardware`, payload);
            return data;
        } catch (error) {
            return error;
        }
    },

};
