import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  assignments: [],
  assignment: {},
});

export const getters = {
  getAssignments(state) {
    return state.assignments;
  },
  getAssignment(state) {
    return state.assignment;
  },
};

export const mutations = {
  SET_ASSIGNMENTS(state, payload) {
    state.assignments = payload;
  },
  SET_ASSIGNMENT(state, payload) {
    state.assignment = payload;
  },
};

export const actions = {
  async updateBarProducts({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `rooms/barProductsByRoomId/${payload.room_id}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
