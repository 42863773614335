import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  users: [],
  user: {},
});

export const getters = {
  getUsers(state) {
    return state.users;
  },
  getUser(state) {
    return state.user;
  },
};

export const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
};

export const actions = {
  async fetchUser({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`user/${payload}`);
      commit("SET_USER", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },

  async fetchUsers({ commit, state }) {
    try {
      const { data } = await ApiService.get("user");
      commit("SET_USERS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async handleCreateUser({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("user", payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async handleDeleteUser({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(`user/${payload}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async handleUpdateUser({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`user/${payload.id}`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
};
