var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"dialog-fade"},on:{"after-enter":_vm.afterEnter,"after-leave":_vm.afterLeave}},[(_vm.destroyOnClose)?[(_vm.visible)?_c('div',{staticClass:"hx-dialog__wrapper",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleWrapperClick.apply(null, arguments)}}},[_c('div',{key:_vm.key,ref:"dialog",class:[
          'hx-dialog',
          {
            'is-fullscreen': _vm.fullscreen,
            'is-square': _vm.square,
            'hx-dialog--center': _vm.center,
          },
          _vm.customClass,
        ],style:(_vm.style),attrs:{"role":"dialog","aria-modal":"true","aria-label":_vm.title || 'dialog'}},[_c('div',{staticClass:"hx-dialog__header"},[_vm._t("header",function(){return [_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"hx-dialog__title"},[_vm._v(_vm._s(_vm.title))]),(_vm.showClose)?_c('button',{staticClass:"hx-dialog__headerbtn",attrs:{"type":"button","icon":"","aria-label":"Close"},on:{"click":_vm.handleClose}},[_c('inline-svg',{staticClass:"hx-dialog__close hx-icon hx-icon-close",attrs:{"src":"/media/icons/close.svg"}})],1):_vm._e()])]},{"close":_vm.handleClose,"show":_vm.showClose})],2),_c('div',{staticClass:"hx-dialog__content flex flex-grow flex-col overflow-y-auto"},[_c('div',{staticClass:"hx-dialog__body"},[_vm._t("default")],2)]),(_vm.$slots.footer)?_c('div',{staticClass:"hx-dialog__footer"},[_vm._t("footer")],2):_vm._e()])]):_vm._e()]:[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"hx-dialog__wrapper",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.handleWrapperClick.apply(null, arguments)}}},[_c('div',{key:_vm.key,ref:"dialog",class:[
          'hx-dialog',
          {
            'is-fullscreen': _vm.fullscreen,
            'is-square': _vm.square,
            'hx-dialog--center': _vm.center,
          },
          _vm.customClass,
        ],style:(_vm.style),attrs:{"role":"dialog","aria-modal":"true","aria-label":_vm.title || 'dialog'}},[_c('div',{staticClass:"hx-dialog__header"},[_vm._t("header",function(){return [_c('div',{staticClass:"flex justify-between items-center"},[_c('span',{staticClass:"hx-dialog__title"},[_vm._v(_vm._s(_vm.title))]),(_vm.showClose)?_c('button',{staticClass:"hx-dialog__headerbtn",attrs:{"type":"button","icon":"","aria-label":"Close"},on:{"click":_vm.handleClose}},[_c('inline-svg',{staticClass:"hx-dialog__close hx-icon hx-icon-close",attrs:{"src":"/media/icons/close.svg"}})],1):_vm._e()])]},{"close":_vm.handleClose,"show":_vm.showClose})],2),_c('div',{staticClass:"hx-dialog__content flex flex-grow flex-col overflow-y-auto"},[_c('div',{staticClass:"hx-dialog__body"},[_vm._t("default")],2)]),(_vm.$slots.footer)?_c('div',{staticClass:"hx-dialog__footer"},[_vm._t("footer")],2):_vm._e()])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }