import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  reservations: [],
});

export const getters = {
  getReservations(state) {
    return state.reservations;
  },
};

export const mutations = {
  SET_RESERVATIONS(state, payload) {
    state.reservations = payload;
  },
};

export const actions = {
  async fetchCheckIn({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/checkIn/${payload.label_id}/${payload.date}`
      );
      commit("SET_RESERVATIONS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateCheckIn({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(`reservations/checkIn`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
};
