import ApiService from "@/core/services/ApiService";
export const namespaced = true;

export const state = () => ({
    selected_label: null,
    stat_period: "1"
});
export const getters = {
    // title(state) {
    //     return state.title;
    // },
};

export const mutations = {
    SET_SELECTED_LABEL(state, payload) {
        state.selected_label = payload;
    },
    SET_SELECTED_PERIOD(state, payload) {
        state.stat_period = payload;
    },
}


export const actions = {
    async setSelectedLabelStat({ commit, state }, payload) {
        await commit("SET_SELECTED_LABEL", payload)
    },
    async setSelectedPeriod({ commit, state }, payload) {
        await commit("SET_SELECTED_PERIOD", payload)
    },

    async updateLabel({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`labels/${payload.get('labelId')}`, payload);
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteLabel({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`labels/${payload}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchTotalStats({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/totalStats/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchBySource({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/bySource/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchByAnalysis({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/byAnalysis/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchRevenueDetails({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/revenueDetails/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },


    async fetchRevenueDistribution({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/revenueDistribution/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchRevenueByRoom({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/revenueByRoom/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },


    async fetchNumberOfReservation({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/numberOfReservation/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchCancelledBySource({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/cancelledBySource/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },

    async fetchAvailability({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`dashboard/card/Availability/${payload.label_id}/${payload.start_date}/${payload.end_date}`);
            return data
        } catch (error) {
            return error;
        }
    },


    // async fetchPackages({ commit, state }) {
    //     try {
    //         const { data } = await ApiService.get("package");
    //         commit("SET_PACKAGES", data.data);
    //         commit("SET_INTERVAL", state.selected_interval);
    //         return data;
    //     } catch (error) { }
    // },
}