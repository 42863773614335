import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  partners: [],
  partner: {},
});

export const getters = {
  getPartners(state) {
    return state.partners;
  },
  getPartner(state) {
    return state.partner;
  },
};

export const mutations = {
  SET_PARTNERS(state, payload) {
    state.partners = payload;
  },
  SET_PARTNER(state, payload) {
    state.partner = payload;
  },
};

export const actions = {
  async fetchPartners({ commit, state }) {
    try {
      const { data } = await ApiService.get(`partners`);
      commit("SET_PARTNERS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchPartner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`partners/${payload.id}/${payload.date ? `${payload.date}` : ''}`);
      commit("SET_PARTNER", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async createPartner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(`partners`, payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updatePartner({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `partners/${payload.partnerId}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
  async updatePaymentStatus({ commit, state }, payload) {
    try {
      const { data } = await ApiService.patch(
        `reservations/partner/transfer/status`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
