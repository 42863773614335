import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    facilities: [],
    facility_groups: [],
    facility: {},
    facility_group: {},
})

export const getters = {
    getFacilities(state) {
        return state.facilities
    },
    getFacility(state) {
        return state.facility
    },
    getFacilityGroups(state) {
        return state.facility_groups
    },
    getFacilityGroup(state) {
        return state.facility_group
    },
}

export const mutations = {
    SET_FACILITIES(state, payload) {
        state.facilities = payload;
    },
    SET_FACILITY(state, payload) {
        state.facility = payload;
    },
    SET_FACILITY_GROUPS(state, payload) {
        state.facility_groups = payload;
    },
    SET_FACILITY_GROUP(state, payload) {
        state.facility_group = payload;
    },
}

export const actions = {

    async fetchFacilityGroup({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`facilities-group/${payload}`)
            commit("SET_FACILITY_GROUP", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchFacilityGroups({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`facilities-group`)
            commit("SET_FACILITY_GROUPS", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchFacility({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`facilities/${payload}`)
            commit("SET_FACILITY", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchFacilities({ commit, state }) {
        try {
            const { data } = await ApiService.get("facilities")
            commit("SET_FACILITIES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async createFacilityGroup({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("facilities-group", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteFacilityGroup({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`facilities-group/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateFacilityGroup({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`facilities-group/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },


    // facilities

    async createFacility({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("facilities", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async deleteFacility({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`facilities/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

    async updateFacility({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`facilities/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}