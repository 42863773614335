import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    static_pages: [],
    static_page: {},
})

export const getters = {
    getStaticPages(state) {
        return state.static_pages
    },
    getStaticPage(state) {
        return state.static_page
    },
}

export const mutations = {
    SET_STATIC_PAGES(state, payload) {
        state.static_pages = payload;
    },
    SET_STATIC_PAGE(state, payload) {
        state.static_page = payload;
    },
}

export const actions = {
    async fetchStaticPage({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`static_pages/${payload}`)
            commit("SET_STATIC_PAGE", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchStaticPages({ commit, state }) {
        try {
            const { data } = await ApiService.get("static_pages")
            commit("SET_STATIC_PAGES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },

    async handleUpdate({ commit, state }, payload) {
        try {

            const { data } = await ApiService.patch(`static_pages/${payload.id}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleCreate({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post(`static_pages`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleDelete({ commit, state }, payload) {
        try {
            const { data } = await ApiService.delete(`static_pages/${payload}`)
            return data
        } catch (error) {
            return error;
        }
    },

}