import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  invoices: [],
  invoice_detail: {}
});

export const getters = {
  getInvoices(state) {
    return state.invoices;
  },
};

export const mutations = {
  SET_INVOICES(state, payload) {
    state.invoices = payload;
  },
  SET_INVOICE_DETAIL(state, payload) {
    state.invoice_detail = payload;
  },
};

export const actions = {
  async fetchInvoices({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/invoice/${payload.labelId}/${payload.from}/${payload.to}`
      );
      commit("SET_INVOICES", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },


  async printInvoice({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/printInvoice/${payload}`
      );
      commit("SET_INVOICE_DETAIL", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async printSingleInvoice({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/printInvoice/${payload.group}/${payload.room}`
      );
      commit("SET_INVOICE_DETAIL", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
};
