import JwtService from "@/core/services/JwtService";

import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  user: {},
  token: "" || JwtService.getToken(),
  isAuthenticated: !!JwtService.getToken(),
  errors: [],
  privacy_policy: {},
  terms: {},
});

export const getters = {
  Authenticated(state) {
    return state.isAuthenticated;
  },
  getPrivacy(state) {
    return state.privacy_policy;
  },
  getTerms(state) {
    return state.terms;
  },
};

export const mutations = {
  SET_AUTH(state, payload) {
    state.isAuthenticated = true;
    state.token = payload.token;
    state.user = payload.user?.message;
    state.errors = [];
    JwtService.saveToken(payload.token);
  },

  SET_TOKEN(state, payload) {
    state.isAuthenticated = true;
    state.token = payload.token;
    state.user = payload.user;
    state.errors = [];
    // JwtService.saveToken(payload.token);
  },

  SET_USER(state, payload) {
    state.user = payload;
    state.errors = [];
  },

  LOGOUT(state) {
    state.isAuthenticated = false;
    state.token = null;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
  },

  SET_PRIVACY(state, payload) {
    state.privacy_policy = payload;
  },

  SET_TERMS(state, payload) {
    state.terms = payload;
  },
};

export const actions = {
  async register({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/register", payload);
      return data;
    } catch (error) { }
  },
  async login({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/login", payload);
      if (data.status == "success") {
        commit("SET_AUTH", data.data);
        ApiService.setHeader();
      }
      return data;
    } catch (error) { }
  },
  async loginAsUser({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/loginAsUser", payload);
      if (data.status == "success") {
        commit("LOGOUT");
        commit("SET_AUTH", data.data);
        ApiService.setHeader();
      }
      return data;
    } catch (error) { }
  },

  async updateUserProfile({ commit }, payload) {
    try {
      const { data } = await ApiService.patch("user/profile", payload);
      if (data.status == "success") {
        commit("SET_USER", data.data);
      }
      return data;
    } catch (error) { }
  },
  async deactivateUser({ commit }, payload) {
    try {
      const { data } = await ApiService.delete(`user/${payload}`);
      if (data.status == "success") {
        commit("LOGOUT");
      }
      return data;
    } catch (error) { }
  },
  async resetPassRequest({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/forgotPassword", payload);
      if (data.status == "success") {
        // commit("SET_AUTH", data.data);
      }
      return data;
    } catch (error) { }
  },
  async resetPassCheckToken({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/check-token", payload);
      if (data.status == "success") {
      }
      return data;
    } catch (error) { }
  },
  async resetPassRecovery({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/change-password", payload);
      if (data.status == "success") {
      }
      return data;
    } catch (error) { }
  },
  async setUser({ commit }, payload) {
    try {
      commit("SET_USER", payload);
    } catch (error) { }
  },
  async verifyAuth({ commit }, payload) {
    try {
      if (JwtService.getToken()) {
        const { data } = await ApiService.get("auth/user", payload);
        if (data.status == "success") {
          if (data.data == null) {
            commit("LOGOUT");
          } else {
            commit("SET_USER", data.data);
          }

        }
        return data;
      }
    } catch (error) { }
  },

  async verifyToken({ commit }, payload) {

    try {
      JwtService.saveToken(payload);

      if (JwtService.getToken()) {
        ApiService.setHeader();
        const { data } = await ApiService.get("auth/user");
        if (data.status == "success") {
          let payload_data = {
            user: data.data,
            token: payload
          }

          commit("SET_TOKEN", payload_data);


        }
        return data;
      }
    } catch (error) { }
  },

  async fetchPrivacyPolicy({ commit }) {
    try {
      const { data } = await ApiService.get("static_pages/slug/privacy_policy");
      if (data.status == "success") {
        commit("SET_PRIVACY", data.data);
      }
      return data;
    } catch (error) { }
  },

  async fetchTerms({ commit }) {
    try {
      const { data } = await ApiService.get(
        "static_pages/slug/terms_and_conditions"
      );
      if (data.status == "success") {
        commit("SET_TERMS", data.data);
      }
      return data;
    } catch (error) { }
  },

  async logout({ commit }, payload) {
    try {
      const { data } = await ApiService.post("auth/logout", payload);
      commit("LOGOUT");
      return data
    } catch (error) { }
  },
  async systemLogout({ commit }, payload) {
    try {
      commit("LOGOUT");
      return data
    } catch (error) { }
  },
};
