import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  requests: [],
  request: {},
});

export const getters = {
  getRequests(state) {
    return state.requests;
  },
  getRequest(state) {
    return state.request;
  },
};

export const mutations = {
  SET_REQUESTS(state, payload) {
    state.requests = payload;
  },
  SET_REQUEST(state, payload) {
    state.request = payload;
  },
};

export const actions = {
  async fetchRequest({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(`appointments/request/${payload}`);
      commit("SET_REQUEST", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchRequests({ commit, state }) {
    try {
      const { data } = await ApiService.get("appointments/request");
      commit("SET_REQUESTS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async CreateRequest({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post("appointments/request", payload);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteRequest({ commit, state }, payload) {
    try {
      const { data } = await ApiService.delete(
        `appointments/request/${payload}`
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
