import ApiService from "@/core/services/ApiService";

export const namespaced = true

export const state = () => ({
    landing_pages: [],
    landing_page: {},
})

export const getters = {
    getLandingPages(state) {
        return state.landing_pages
    },
    getLandingPage(state) {
        return state.landing_page
    },
}

export const mutations = {
    SET_LANDING_PAGES(state, payload) {
        state.landing_pages = payload;
    },
    SET_LANDING_PAGE(state, payload) {
        state.landing_page = payload;
    },
}

export const actions = {
    async fetchLandingPage({ commit, state }, payload) {
        try {
            const { data } = await ApiService.get(`landing_pages/${payload}`)
            commit("SET_LANDING_PAGE", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async fetchLandingPages({ commit, state }) {
        try {
            const { data } = await ApiService.get("landing_pages")
            commit("SET_LANDING_PAGES", data.data);
            return data
        } catch (error) {
            return error;
        }
    },
    async handleCreate({ commit, state }, payload) {
        try {
            const { data } = await ApiService.post("landing_pages", payload)
            return data
        } catch (error) {
            return error;
        }
    },

    async handleUpdate({ commit, state }, payload) {
        try {
            const { data } = await ApiService.patch(`landing_pages/${payload.get('id')}`, payload)
            return data
        } catch (error) {
            return error;
        }
    },

}