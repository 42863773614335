export default
    {
        path: "/auth",
        component: () =>
            import(/* webpackChunkName: "auth" */ "@/layouts/auth.vue"),
        children: [
            {
                path: "password/recovery/:token",
                name: "auth-password-recovery",
                component: () =>
                    import(/* webpackChunkName: "auth-password-recovery" */ "@/views/auth/pass-recovery.vue"),
            },
        ],
    }