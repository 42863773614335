export default
    {
        path: "/reservation",
        component: () =>
            import(/* webpackChunkName: "reservation-layout" */ "@/layouts/reservation.vue"),
        children: [
            {
                path: "placement/group/:id",
                name: "reservation placement group",
                component: () =>
                    import(/* webpackChunkName: "reservation-placement-group" */ "@/views/reservation/placement/group.vue"),
            },
            {
                path: "placement/individual/:id",
                name: "reservation placement individual",
                component: () =>
                    import(/* webpackChunkName: "reservation-placement-individual" */ "@/views/reservation/placement/individual.vue"),
            },
        ],
    }