import ApiService from "@/core/services/ApiService";

export const namespaced = true;

export const state = () => ({
  bookings: [],
  booking: {},
});

export const getters = {
  getBookings(state) {
    return state.bookings;
  },
  getBooking(state) {
    return state.booking;
  },
};

export const mutations = {
  SET_BOOKINGS(state, payload) {
    state.bookings = payload;
  },
  SET_BOOKING(state, payload) {
    state.booking = payload;
  },
};

export const actions = {
  async fetchBookings({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/bookingN/groupId/${payload}`
      );
      commit("SET_BOOKINGS", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async fetchBooking({ commit, state }, payload) {
    try {
      const { data } = await ApiService.get(
        `reservations/bookingN/group/${payload}`
      );
      commit("SET_BOOKING", data.data);
      return data;
    } catch (error) {
      return error;
    }
  },
  async updateBookings({ commit, state }, payload) {
    try {
      const { data } = await ApiService.post(
        `reservations/bookingN/group/${payload}`,
        payload
      );
      return data;
    } catch (error) {
      return error;
    }
  },
};
