export default {
  path: "/setting",
  component: () =>
    import(/* webpackChunkName: "setting-layout" */ "@/layouts/setting.vue"),
  meta: {
    auth: true,
  },
  children: [
    {
      path: "assignment/:id",
      name: "label setting assignment",
      component: () =>
        import(
          /* webpackChunkName: "label-assignment" */ "@/views/setting/Assignment.vue"
        ),
    },
    {
      path: "housekeeping/:id",
      name: "label setting housekeeping",
      component: () =>
        import(
          /* webpackChunkName: "label-housekeeping" */ "@/views/setting/Housekeeping"
        ),
    },
    {
      path: "bar/:id",
      name: "label setting bar",
      component: () =>
        import(/* webpackChunkName: "label-bar" */ "@/views/setting/Bar.vue"),
    },
    {
      path: "personal",
      name: "label setting personal",
      component: () =>
        import(
          /* webpackChunkName: "label-personal" */ "@/views/setting/Personal.vue"
        ),
    },
  ],
};
